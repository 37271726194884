import React from 'react'
// import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby' 

const Image = ({ imgName }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(
                width: 720
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
              fluid {
                originalName
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find(
        (edge) => edge.node.fluid?.originalName.split('.')[0] === imgName
      )
      if (!image) {
        console.error('Image not found', imgName)
        return null
      }
      const imageG = getImage(image.node.gatsbyImageData)

      return (
        <GatsbyImage
          image={imageG}
          alt={image.node.fluid.originalName} 
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
          }}
        />
      )
    }}
  />
)

class BannerLanding extends React.Component {
  render() {
    const { cover, title, description } = this.props 

    return (
      <section id="banner" className="style2">
        {cover !== undefined ? <Image imgName={cover} /> : <div></div>} 
        <div className="inner">
          <header className="major" onClick={() => navigate('/#')}>
            <h1 className='title'>{title}</h1>
          </header>
          <div className="content">
            <p> {description}</p>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerLanding
