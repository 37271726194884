import React, {useRef, useState, useEffect} from "react";
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {Layout} from "../components/Layout";
import BannerLanding from '../components/BannerLanding';
import SEO from "../components/seo";

import {useOnScreenThresh} from '../utils/useOnScreen';

const Image = ({ imgName  }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {

              gatsbyImageData(
                  width: 1080
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                fluid {
                  originalName
                }
            }
          }
        }
      }
    `}

    render={ data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid?.originalName.split('.')[0] === imgName
      );
      if (!image) {
        console.error("Image not found", imgName);
        return null;
      }
      const imageG = getImage(image.node.gatsbyImageData)

      return <GatsbyImage image={imageG} alt={image.node.fluid.originalName} />;
    }}    
  />
);


const PageTemplate = ({pageContext }) => {
  const { seccionTitle, description, cover, album } = pageContext;

  const ref = useRef();
  const isVisible = useOnScreenThresh(ref);
  const [items, setItems] = useState(album.slice(0, 5).reverse()) 

 useEffect( () => {
     //console.log({isVisible, album, items})
      if (isVisible) {
        setItems([ ...album].reverse())
      }
    }  
  ,[isVisible]);

  return (
      <Layout >
        <SEO title={seccionTitle} description={description}/>
        <BannerLanding  title={seccionTitle} 
          description={description} 
          cover={cover} />

        <section id="landing-two" className="landing-tiles">      
            {
              items.map( (item) => (
                <Image key={item} imgName={item}  />                       
              ) )          
            }                 
        </section>
        <div ref={ref}></div>
      </Layout>

  );
};

export default PageTemplate;
